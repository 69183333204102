<template>
  <v-app>
    <dashboard-launch />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardNewLaunch',

    components: {
      DashboardLaunch: () => import('./Launch'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
